<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span>{{ lang("t_mAlerts") }}</span>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                variant="primary"
                @click="
                  isEdit = false;
                  modal_record_details = true;
                  clear_data();
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchQuery"
      >
        <template #cell(profiles)="data">
          {{ getProfileDisplayNames(data.item.profiles) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(break_time)="data">
          {{ parseInt(data.item.break_time / 60) }}
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ records.length }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="setAlert"
    >
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_displayName')">
            <b-form-input
              v-model="alert_info.display_name"
              :placeholder="lang('t_displayName')"
              trim
            /> </b-form-group
        ></b-col>
      </b-row>
      <div>
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_alertCategory')">
              <v-select
                :placeholder="lang('t_alertCategory')"
                v-model="alert_info.alert_category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="alert_categories"
                @input="set_target_types"
              /> </b-form-group
          ></b-col>
          <b-col v-if="alert_info.alert_category == 'agent'">
            <b-form-group :label="lang('t_targetType')">
              <v-select
                multiple
                :placeholder="lang('t_targetType')"
                v-model="alert_info.target_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.identifier"
                :options="agents"
              /> </b-form-group
          ></b-col>
          <b-col v-else>
            <b-form-group :label="lang('t_targetType')">
              <v-select
                multiple
                :placeholder="lang('t_targetType')"
                v-model="alert_info.target_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="target_types"
              /> </b-form-group
          ></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_targetCategory')">
              <v-select
                :placeholder="lang('t_targetCategory')"
                v-model="alert_info.target_category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="
                  target_categories.filter((e) =>
                    e.type.includes(alert_info.alert_category)
                  )
                "
                @input="change_target_category"
              /> </b-form-group
          ></b-col>
          <b-col v-if="alert_info.target_category == 'finish_code'">
            <b-form-group :label="lang('t_target')">
              <v-select
                :placeholder="lang('t_target')"
                v-model="alert_info.target"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="finish_code"
                :reduce="(item) => item.finish_code"
                :options="finish_codes"
              /> </b-form-group
          ></b-col>
          <b-col v-else>
            <b-form-group :label="lang('t_target')">
              <v-select
                :placeholder="lang('t_target')"
                v-model="alert_info.target"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.display_name"
                :options="statuses"
              /> </b-form-group
          ></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_operator')">
              <v-select
                :options="['=', '!=', '<', '>', '<=', '>=']"
                v-model="alert_info.operand"
              /> </b-form-group
          ></b-col>
          <b-col>
            <b-form-group :label="lang('t_value')" label-for="t_value">
              <b-form-input
                type="number"
                autofocus
                v-model="alert_info.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_communicationType')">
              <v-select
                @input="communication_type_change"
                :options="communication_types"
                v-model="alert_info.communication_type"
                label="display_name"
                :reduce="(item) => item.internal_name"
              /> </b-form-group
          ></b-col>
          <b-col>
            <b-form-group
              v-if="alert_info.communication_type == 'mail'"
              :label="lang('t_communicationSrc')"
              label-for="t_value"
            >
              <v-select
                :options="email_settings"
                v-model="alert_info.communication_src"
                label="display_name"
                :reduce="(item) => item"
              />
            </b-form-group>
            <b-form-group
              v-if="alert_info.communication_type == 'sms'"
              :label="lang('t_communicationSrc')"
              label-for="t_value"
            >
              <v-select
                :options="sms_settings"
                v-model="alert_info.communication_src"
                label="display_name"
                :reduce="(item) => item"
              />
            </b-form-group>

            <b-form-group
              v-if="alert_info.communication_type == 'http'"
              :label="lang('t_type')"
            >
              <v-select
                v-model="alert_info.http_info.type"
                :options="['GET', 'POST', 'PUT', 'DELETE']"
                :placeholder="lang('t_type')"
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="alert_info.communication_type == 'http'">
          <b-col>
            <b-form-group :label="lang('t_url')">
              <b-form-input
                v-model="alert_info.http_info.url"
                trim
                :placeholder="lang('t_url')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="alert_info.communication_type == 'sms'">
          <b-col>
            <b-form-group :label="lang('t_rRemoteNumber')">
              <b-form-input
                v-model="alert_info.communication_destination"
                trim
                :placeholder="lang('t_rRemoteNumber')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="alert_info.communication_type == 'mail'">
          <b-col>
            <b-form-group :label="lang('t_to')">
              <b-form-input
                v-model="alert_info.communication_destination"
                trim
                :placeholder="lang('t_to')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="alert_info.communication_type != 'http'">
          <b-col>
            <b-form-group :label="lang('t_yourMessage')">
              <b-form-textarea
                rows="3"
                v-model="alert_info.communication_text"
                trim
                :placeholder="lang('t_yourMessage')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="alert_info.communication_type == 'http'">
          <b-col>
            <b-form-group :label="lang('t_headers')">
              <b-form-textarea
                rows="4"
                v-model="alert_info.http_info.headers"
                trim
                :placeholder="lang('t_headers')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="lang('t_body')">
              <b-form-textarea
                rows="4"
                v-model="alert_info.http_info.body"
                trim
                :placeholder="lang('t_body')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="
            ['notification', 'mail'].includes(alert_info.communication_type)
          "
        >
          <b-col>
            <b-form-checkbox
              v-model="alert_info.inform_agent"
              class="custom-control-primary"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                  {{ lang("t_informAgent") }}
                </span>
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import cronParser from "cron-parser";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
globalThis.cronParser = cronParser;
export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
  },

  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      modal_record_details: false,
      cronstrue: "",
      perPage: 1,
      totalUsers: 0,
      perPageOptions: "",
      searchQuery: "",
      sortBy: "",
      isSortDirDesc: "",
      currentPage: 1,
      agents: [],
      projects: [],
      campaigns: [],
      queues: [],
      ivr_list: [],
      finish_codes: [],
      statuses: [],
      target_types: [],
      email_settings: [],
      sms_settings: [],

      target_categories: [
        {
          type: ['agent', 'queue', 'project', 'campaign'],
          internal_name: "finish_code",
          display_name: globalThis._lang("t_finishCode"),
        },
        {
          type: ['agent'], internal_name: "status", display_name: globalThis._lang("t_status")
        },
      ],
      alert_categories: [
        { internal_name: "agent", display_name: globalThis._lang("t_agent") },
        { internal_name: "queue", display_name: globalThis._lang("t_queue") },
        {
          internal_name: "project",
          display_name: globalThis._lang("t_project"),
        },
        {
          internal_name: "campaign",
          display_name: globalThis._lang("t_campaign"),
        },
      ],
      alert_types: [
        { internal_name: "agent", display_name: globalThis._lang("t_agent") },
        { internal_name: "queue", display_name: globalThis._lang("t_queue") },
        {
          internal_name: "project",
          display_name: globalThis._lang("t_project"),
        },
        {
          internal_name: "campaign",
          display_name: globalThis._lang("t_campaign"),
        },
      ],

      communication_types: [
        {
          internal_name: "notification", display_name: globalThis._lang("t_notification")
        },
        { internal_name: "mail", display_name: globalThis._lang("t_email") },
        {
          internal_name: "sms", display_name: globalThis._lang("t_sms")
        },
        {
          internal_name: "http", display_name: globalThis._lang("t_httpRequest")
        },
      ],
      alert_info: {
        display_name: "",
        alert_category: "agent",
        target_type: "",
        target_category: "status",
        target: "",
        operand: "=",
        value: 0,
        communication_type: 'notification',
        communication_src: null,
        communication_destination: '',
        communication_text: '',
        inform_agent: false,
        http_info: {
          type: "GET",
          url: "",
          body: `{\n}`,
          headers: `{\n"X-Client":"Voyce"\n}`,
        }
      },
      isEdit: false,
      records: [],
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "username",
          label: globalThis._lang("t_username"),
          sortable: true,
        },
        {
          key: "create_date",
          label: globalThis._lang("t_date"),
          sortable: true,
        },
        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
    };
  },
  methods: {
    communication_type_change() {
      this.alert_info = {
        ...this.alert_info,
        communication_src: null,
        http_info: {
          type: "GET",
          url: "",
          body: `{\n}`,
          headers: `{\n"X-Client":"Voyce"\n}`,
        },
        communication_destination: '',
        communication_text: '',
        inform_agent: false,

      };
    },
    change_target_category() {
      this.alert_info.target = "";
    },
    set_target_types() {
      if (this.alert_info.alert_category == "queue") {
        this.target_types = this.queues;
      }
      if (this.alert_info.alert_category == "project") {
        this.target_types = this.projects;
      }
      if (this.alert_info.alert_category == "campaign") {
        this.target_types = this.campaigns;
      }
      this.alert_info.target_type = "";
    },
    updateSelected(item) {
      this.alert_info = {
        ...item,
      };
      this.isEdit = true;
      this.modal_record_details = true;
    },

    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/alert/v1/Alert `)).data;

      this.records = response;
      this.clear_data();
      this.is_progress = false;
    },

    clear_data() {
      this.alert_info = {
        display_name: "",
        alert_category: "",
        target_type: "",
        target_category: "",
        target: "",
        operand: "=",
        value: 0,
        communication_type: 'notification',
        communication_src: null,
        http_info: {
          type: "GET",
          url: "",
          body: `{\n}`,
          headers: `{\n"X-Client":"Voyce"\n}`,
        }
      };
    },
    confirmText(item) {
      this.alert_info = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteAlert();
        }
      });
    },
    setAlert: async function () {
      try {
        this.is_progress = true;
        let response;
        if (!this.isEdit) {
          response = (
            await this.$http_in.post(
              `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/alert/v1/Alert`,
              this.alert_info
            )
          ).data;
        } else {
          response = (
            await this.$http_in.put(
              `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/alert/v1/Alert`,
              this.alert_info
            )
          ).data;
        }

        if (response && response.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.get_records();
        this.is_progress = false;
      }
    },
    deleteAlert: async function () {
      try {
        this.is_progress = true;
        let response = (
          await this.$http_in.delete(
            `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/alert/v1/Alert/${this.alert_info._id}`
          )
        ).data;

        if (response && response.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.get_records();
        this.is_progress = false;
      }
    },

    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;

      this.agents = response;
    },

    GetEMailSettings: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/EMailSettings`)).data;
      this.email_settings = response;
    },
    GetSmsSettings: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/SmsSettings`)).data;
      this.sms_settings = response;
    },

    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          // this.selected_queues.push(item.internal_name);
        } else {
          this.queues.filter(e => e.internal_name != item.internal_name)
        }
      }
    },
    GetFinishCodes: async function () {
      var response = (await this.$http_in.get(`spv/v1/FinishCodes`)).data;

      this.finish_codes = response;
    },
    GetStatus: async function () {
      var response = (await this.$http_in.get(`spv/v1/Status`)).data;

      this.statuses = response;
    },
    GetCampaigns: async function () {
      var response = (await this.$http_in.get(`report/v1/Campaign`)).data;

      this.campaigns = response;
    },

    GetIVR: async function () {
      var response = (await this.$http_in.get(`report/v1/Ivr`)).data;

      this.ivr_list = response;
    },
  },

  mounted: async function () {
    this.is_progress = true;
    this.get_records();
    this.get_users();
    this.GetProjects();
    this.GetQueues();
    this.GetCampaigns();
    this.GetFinishCodes();
    this.GetStatus();
    this.GetEMailSettings();
    this.GetSmsSettings();

    this.is_progress = false;
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
